import React from "react";
import "./app.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LoadingIndicator from "./components/LoadingIndicator";
import History from "./components/History";
import Team from "./components/Team";
import AppDescription from "./components/AppDescription";
import Decode from "./components/Decode";
import LinkSection from "./components/LinkSection";

const App: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const checkDocumentStatus = (): void => {
    let DOMStatus: string = document.readyState;
    if (DOMStatus === "complete") {
      setIsLoading(false);
      clearInterval(loadingInterval);
    }
  };
  let loadingInterval = setInterval(checkDocumentStatus, 500);

  if (isLoading) return <LoadingIndicator />;

  return (
    <div className="App">
      <Header />
      <History />

      <Team />
      <AppDescription />
      <LinkSection />
      <Decode />

      <Footer />
    </div>
  );
};

export default App;
