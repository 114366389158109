import React from "react";
import "./Header.css";

const headerText: string = "Darivanje krvi";
const headingText: string = "Aplikacija za praćenje stanja Vaše krvne grupe";

const Header: React.FC = () => {
  return (
    <header>
      <h1>{headerText}</h1>
      <h2>{headingText}</h2>
    </header>
  );
};

export default Header;
