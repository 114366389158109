import React from "react";
import AppShowcase1 from "../../assets/App-Showcase-1.png";
import "./History.css";
const History: React.FC = () => {
  return (
    <section id="history">
      <section id="history-content">
        <h3>Povijest</h3>
        <p>
          Hackathoni možda jesu demodirani, ali kad se rade s nekom većom
          svrhom, postaju mnogo više od fora druženja na kojem se kodira. Upravo
          tako je nastala aplikacija <span id="app-name">Darivanje krvi</span>
        </p>
      </section>
      <img src={AppShowcase1} alt="App showcase 1" />
    </section>
  );
};
export default History;
