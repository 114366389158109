import React from "react";
import "./Team.css";
import employee1 from "../../assets/employee-1.jpg";
import employee2 from "../../assets/employee-2.jpg";
import employee3 from "../../assets/employee-3.jpg";
import employee4 from "../../assets/employee-4.jpg";
interface ISingleEmployee {
  src: string;
  alt: string;
  name: string;
}
const Team: React.FC = () => {
  const employeesArray: ISingleEmployee[] = [
    {
      src: employee1,
      alt: "Employee one",
      name: "David Halapir",
    },
    {
      src: employee2,
      alt: "Employee twp",
      name: "Tomislav Tkalčević",
    },
    {
      src: employee3,
      alt: "Employee two",
      name: "Vladimir Kolbas",
    },
    {
      src: employee4,
      alt: "Employee four",
      name: "Ivan Trogrlić",
    },
  ];
  return (
    <section id="team">
      <section id="team-heading">
        <h3>Tim iza aplikacije</h3>
      </section>
      <section id="team-imgs">
        {employeesArray.map((item: ISingleEmployee) => (
          <div key={item.name} className="employee-item">
            <img src={item.src} alt={item.alt} />
            <span>{item.name}</span>
          </div>
        ))}
      </section>
    </section>
  );
};

export default Team;
