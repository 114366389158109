import React from "react";
import playstore from "../../assets/play-store.png";
import applestore from "../../assets/apple-store.png";

import "./LinkSection.css";

interface IStoreItem {
  src: string;
  href: string;
  alt: string;
  id: string;
}
const LinkSection: React.FC = () => {
  const storeItems: IStoreItem[] = [
    {
      src: playstore,
      href:
        "https://play.google.com/store/apps/details?id=agency.decode.darivanjekrvi",
      alt: "Play store link",
      id: "play-store",
    },
    {
      src: applestore,
      href: "https://apps.apple.com/hr/app/darivanje-krvi/id1531982081",
      alt: "Apple store link",
      id: "app-store",
    },
  ];
  return (
    <section id="store-links">
      <section id="store-links-heading">
        <h3>Preuzmite na:</h3>
      </section>
      <section id="link-section">
        {storeItems.map((item: IStoreItem) => (
          <a key={item.href} href={item.href}>
            <img
              id={item.id}
              src={item.src}
              alt={item.alt}
              className="store-link"
            />
          </a>
        ))}
      </section>
    </section>
  );
};
export default LinkSection;
