import React from "react";
import "./AppDescription.css";
import AppShowcase2 from "../../assets/App-Showcase-2.png";

const AppDescription: React.FC = () => {
  return (
    <section id="app-description">
      <section id="app-description-content">
        <h3>O aplikaciji</h3>
        <p>
          Aplikacija omogućuje brzu i jednostavnu provjeru stanja zalihe krvi u
          jednom od tri podržana transfuzijska centra - Rijeci, Varaždinu i
          Zagrebu.
        </p>
      </section>
      <img src={AppShowcase2} alt="App showcase 2" />
    </section>
  );
};

export default AppDescription;
