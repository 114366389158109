import React from "react";
import "./Decode.css";
const Decode: React.FC = () => {
  return (
    <section id="decode-link-section">
      <a id="decode-link" href="https://decode.agency/">
        Više o DECODE-u
      </a>
    </section>
  );
};
export default Decode;
