import React from "react";
import "./LoadingIndicator.css";
const LoadingIndicator: React.FC = () => {
  return (
    <section id="loading-indicator">
      <div id="droplet"></div>
    </section>
  );
};
export default LoadingIndicator;
